import * as actionTypes from './actionTypes'

export const paygPopupShow = () => {
  return {
    type: actionTypes.PAYG_POPUP_SHOW,
  }
}

export const paygPopupHide = () => {
  return {
    type: actionTypes.PAYG_POPUP_HIDE,
  }
}
