import React from 'react'
import * as Sentry from '@sentry/browser'

import Logo from '../Logo/Logo'
import branding from '../../shared/branding'
import * as Router from '../../shared/router'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, eventId: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV !== 'production') {
      return
    }

    const setState = this.setState.bind(this)

    Sentry.withScope(function (scope) {
      scope.setExtras(info)
      const eventId = Sentry.captureException(error)
      setState({ eventId: eventId })
    })
  }

  handleClearHistory() {
    window.location = Router.clearhHistory
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="d-flex flex-column align-items-center justify-content-center m-4">
          <Logo width={250} />
          <div className="d-flex flex-column p-4">
            <h1 className="text-danger" style={{ fontSize: '64px' }}>
              500
            </h1>
            <h2 className="text-danger">
              Whoops, something went wrong on our end.
            </h2>
          </div>
          <hr className="mr-0 ml-0 w-100" />
          <div className="d-block">
            <p>
              Try refreshing the page, or going back and attempting the action
              again.
            </p>
            <p>
              Please contact your {branding.NAME} administrator if this problem
              persists.
            </p>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report Error
            </button>
            <button
              type="button"
              className="btn btn-primary ml-3"
              onClick={this.handleClearHistory}
            >
              Clear Cache/History
            </button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
