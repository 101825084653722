import React from "react";

const SvgLogoFull = props => (
  <svg viewBox="0 0 321.5 98" {...props} style={{ padding: '10px 0' }}>
    <path
      d="M151.9 33.4c3 3 4.5 7.4 4.5 13.3v22h-4.2V46.9c0-4.6-1.1-8.1-3.4-10.6-2.3-2.4-5.5-3.7-8.9-3.7h-2.7c-3.3 0-6.5 1.3-8.9 3.7-2.3 2.5-3.4 6-3.4 10.6v21.9h-4.2v-22c0-5.9 1.5-10.4 4.5-13.3 3-2.9 7-4.4 12-4.4h2.7c5-.1 9 1.4 12 4.3zm49.9-4h3.6v36.9c0 6.2-1.6 10.8-4.9 13.8-3.2 3-8.2 4.5-15 4.5-5.2 0-9.4-1.1-12.7-3.3-3.3-2.2-5.4-5.6-5.9-9.5h4.4c.6 2.9 2.3 5.3 4.8 6.9 3 1.7 6.3 2.6 9.8 2.4 5.2 0 9-1.2 11.5-3.6 2.5-2.4 3.8-6.2 3.8-11.4v-6.9c-1.3 3-3.4 5.5-6.2 7.3-3 1.8-6.4 2.7-9.9 2.6-3.5.1-7-.8-10.1-2.5-3-1.6-5.4-4-7-7-1.7-3.3-2.6-7-2.5-10.7-.1-3.6.8-7.2 2.5-10.4 1.6-3 4-5.4 7-7.1 3.1-1.7 6.6-2.6 10.2-2.5 3.4-.1 6.8.8 9.8 2.5 2.7 1.7 4.8 4.1 6.1 7l.7-9zm-.5 19.7c0-3-.6-6-2-8.7-1.3-2.5-3.2-4.5-5.6-5.9-5.1-2.8-11.3-2.8-16.4 0-2.4 1.4-4.3 3.4-5.6 5.9-1.4 2.7-2 5.6-2 8.6s.6 5.9 2 8.6c1.3 2.5 3.2 4.5 5.6 5.9 5.1 2.8 11.3 2.8 16.4 0 2.4-1.4 4.3-3.4 5.6-5.8 1.3-2.7 2-5.6 2-8.6zm14 19.6h4.2V29.4h-4.2v39.3zM248.6 29h-2.7c-5 0-9 1.5-12 4.4-3 2.9-4.5 7.4-4.5 13.3v22h4.2V46.9c0-4.6 1.1-8.1 3.4-10.6 2.3-2.4 5.5-3.7 8.9-3.7h2.7c3.3-.1 6.5 1.3 8.9 3.7 2.3 2.5 3.4 6 3.4 10.6v21.9h4.2v-22c0-5.9-1.5-10.4-4.5-13.3-3-3.1-7-4.5-12-4.5zm29.8 20.1c-.1.3-.1.6-.1.9 0 2.8.7 5.6 2.1 8.1 1.3 2.3 3.2 4.2 5.5 5.5 2.4 1.3 5.1 2 7.8 2 3.2.1 6.3-.9 8.9-2.7 2.5-1.7 4.3-4.3 5-7.2h4.3c-.9 3.9-3.1 7.3-6.3 9.8-3.3 2.5-7.4 3.8-11.6 3.7-3.6.1-7.2-.8-10.4-2.6-3-1.6-5.5-4.1-7.1-7.1-1.7-3.2-2.6-6.9-2.5-10.6-.1-3.6.8-7.2 2.4-10.5 1.6-3 3.9-5.4 6.8-7.1 3.1-1.7 6.5-2.6 10-2.5 3.4-.1 6.8.7 9.8 2.2 2.8 1.4 5.2 3.7 6.8 6.4 1.7 3 2.7 6.4 2.7 9.9 0 .5 0 1.1-.1 1.6l-34 .2zm.2-3.3h29.5c-.4-4.2-1.9-7.5-4.6-9.8-4.9-3.9-11.6-4.6-17.3-1.8-4.4 2.2-7.3 6.6-7.6 11.6z"
      fill="#101820"
    />
    <path
      d="M63.8 13.4C46.6 3.2 24.3 8.9 14.1 26.2S9.6 65.7 26.8 75.9c18.8 11.1 54.4 4.7 60.8-6.3s-5-45.1-23.8-56.2zm-13 48.8c-8 0-14.4-6.5-14.4-14.4s6.5-14.4 14.4-14.4 14.4 6.5 14.4 14.4-6.4 14.4-14.4 14.4z"
      fill="#6fcfeb"
    />
    <path
      d="M69.4 9.3c-11.5-5.5-44.5 9-54 28.7-8.7 18.1-1 39.8 17.1 48.5s39.8 1 48.5-17.1c9.3-19.7-.2-54.6-11.6-60.1zM50.8 62.2c-8 0-14.4-6.5-14.4-14.4s6.5-14.4 14.4-14.4 14.4 6.5 14.4 14.4-6.4 14.4-14.4 14.4z"
      fill="#eb0045"
    />
    <path
      d="M83.8 38.3c-4.5-10.2-11.5-19.9-20-24.9-3-1.8-6.2-3.1-9.5-4-14.6 3.7-32.4 15-38.9 28.6-3.4 6.8-4.4 14.5-3 21.9 3.1 6.6 8.2 12.2 14.5 16 13 7.7 34.5 6.8 48.4 2 2.3-2.5 4.2-5.4 5.7-8.5 4.1-8.7 4.6-20.4 2.8-31.1zm-33 23.9c-8 0-14.4-6.5-14.4-14.4s6.5-14.4 14.4-14.4c8 0 14.4 6.5 14.4 14.4s-6.4 14.4-14.4 14.4z"
      fill="#60269e"
    />
  </svg>
);

export default SvgLogoFull;
