import React, { useState, useEffect } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import {
  menuUserCloseService,
  menuUserService,
} from '../../services/tourGuideService/menuUserService'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import clientLogin from '../../shared/clientLogin'

const initSteps = [
  {
    target: '.users-detail',
    title: 'Account Menu',
    content:
      'Your profile is displayed on top of the Account Menu, which consists of your profile picture, full name, job title, and email address. You can change this information from the Manage Profile setting.',
    disableBeacon: true,
  },
  {
    target: '.manage-profile-menu',
    title: 'Manage Profile ',
    content:
      'This selection will navigate you to the Manage Profile screen, where you can set your personal, company and contact Information.',
    disableBeacon: true,
    spotlightPadding: 2,
  },
  {
    target: '.manage-payment-menu',
    title: 'Manage Payment/Invoices',
    content:
      'This selection will navigate you to Manage Payment/Invoices screen, where you can manage the payment method and storage of invoices and receipts of payments.',
    disableBeacon: true,
    spotlightPadding: 2,
  },
  {
    target: '.manage-team-member-menu',
    title: 'Manage Team Member',
    content:
      'This selection will navigate you to Manage Team Member screen, where you can manage your team members.',
    disableBeacon: true,
    spotlightPadding: 2,
  },
  {
    target: '.general-settings-menu',
    title: 'General Settings',
    content:
      'This selection will navigate you to the General Settings screen, where you can manage your Account Password, Notification Settings and Account Deletion.',
    disableBeacon: true,
    lastStep: true,
    spotlightPadding: 2,
  },
]

function UserMenuTourGuide({ run }) {
  const [autoRun, setAutoRun] = useState(false)
  const [steps, setSteps] = useState([])

  const customStyles = {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    buttonClose: {
      display: 'none',
    },
  }

  useEffect(() => {
    const clientId = localStorage.getItem('clientId')
    if (clientId === null) return false

    if (clientId === clientLogin.ADMIN) {
      setSteps([initSteps[0], initSteps[1], initSteps[4]])
    } else {
      setSteps(initSteps)
    }
  }, [])

  const handleJoyrideCallback = (data) => {
    const { status, lifecycle } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle === 'complete'
    ) {
      tourGuideAction('userMenu', 'false', () => {
        userDetailService.emitReload(true)
        menuUserCloseService.emitReload(false)
      })
    }
  }

  useEffect(() => {
    if (autoRun) {
      menuUserCloseService.emitReload(true)
    }
  }, [autoRun])

  useEffect(() => {
    const subscription = menuUserService.reload().subscribe((value) => {
      if (value) {
        setAutoRun(run)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [run])

  return (
    <div className="tour-guide-user-menus">
      <Joyride
        callback={handleJoyrideCallback}
        run={autoRun}
        steps={steps}
        styles={customStyles}
        showSkipButton={true}
        floaterProps={{ disableAnimation: true }}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        continuous={true}
      />
    </div>
  )
}

export default UserMenuTourGuide
