import { useEffect } from 'react'
import { tidioCode } from '../shared/constant'
import userRole from '../shared/userRole'

function isInternalStaff(user) {
  return (
    user &&
    user.role &&
    (user.role === userRole.CREATIVE_MANAGER ||
      user.role === userRole.MANAGER ||
      user.role === userRole.PRODUCTION_MANAGER ||
      user.role === userRole.OPERATOR)
  )
}

function useLiveChat(user) {
  useEffect(() => {
    if (!isInternalStaff(user) && process.env.NODE_ENV === 'production') {
      const script = document.createElement('script')
      script.src = `//code.tidio.co/${tidioCode}.js`
      script.async = true
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
        const tidioChatCode = document.getElementById('tidio-chat-code')
        const tidioChat = document.getElementById('tidio-chat')
        if (tidioChatCode !== null) {
          tidioChatCode.remove()
        }
        if (tidioChat !== null) {
          tidioChat.remove()
        }
      }
    }
  }, [user])
}

export default useLiveChat
