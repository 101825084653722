import { baseURLEndpoint } from './constant'

export default {
  NAME: 'ngine',
  LOGIN_TITLE: ' ngine - Login | Unlimited Graphic Design Service',
  KANBAN: 'https://commercialapp.ngine.com.au',
  DOMAIN: baseURLEndpoint.substring(0, baseURLEndpoint.length - 6),
  REGISTER:
    baseURLEndpoint.substring(0, baseURLEndpoint.length - 6) + 'pricing-plans',
}
