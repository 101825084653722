import * as types from './actionTypes'
import { UserDetailAPI } from '../../api/User/UserDetail'
import toastCenter from '../../shared/toastCenter'
import { logout, authSuccess, authFail } from './auth'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import { ClientDetailAPI } from '../../api/Clients/ClientDetailAPI'
import { versioningtDisplayDate } from '../../shared/dateTimeHelper'
import {
  isClientOrCustomer,
  updateFavicon,
  updateColorBased,
} from '../../shared/utility'
import {
  userScheduleNotificationContent,
  userEmailVerificationNotificationContent,
  userRequestDeletionNotificationContent,
} from './flashNotification'

export const successLoaded = (user) => ({
  type: types.USER_LOADED_SUCCESS,
  user,
})
export const failedLoaded = () => ({
  type: types.USER_LOADED_FAILED,
  user: {},
})

const handleUserFlashNotification = (user) => (dispatch) => {
  if (user.isScheduledDowngrade) {
    setTimeout(() => {
      const action = 'downgrade',
        key = 'cancel-downgrade',
        date = user.membership.downgradeDate
      dispatch(userScheduleNotificationContent(action, key, date))
    }, 750)
  }
  if (user.isScheduledUpgrade) {
    setTimeout(() => {
      dispatch(userScheduleNotificationContent('upgrade', 'cancel-upgrade'))
    }, 750)
  }
}

const handleUserEmailVerificationNotification = (user) => (dispatch) => {
  if (!user.emailIsVerified) {
    setTimeout(() => {
      dispatch(userEmailVerificationNotificationContent())
    }, 750)
  }
}

const handleUserRequestDeletionNotification = (user) => (dispatch) => {
  if (user.isRequestDeletion && user.endSubscriptionCycleOn != null) {
    setTimeout(() => {
      dispatch(
        userRequestDeletionNotificationContent(
          versioningtDisplayDate(new Date(user.endSubscriptionCycleOn))
        )
      )
    }, 750)
  }
}

export const loadUserDetail = () => (dispatch) => {
  const token = localStorage.getItem('token')
  const clientId = localStorage.getItem('clientId')
  const currentView = localStorage.getItem('view')

  if (token) {
    const userDetailAPI = new UserDetailAPI()
    const clientDetailAPI = new ClientDetailAPI()
    const userId = localStorage.getItem('userId')

    const onNext = (response) => {
      if (response.success) {
        const user = response.data
        dispatch(successLoaded(user))
        dispatch(authSuccess(token, userId, user.role, clientId))
        dispatch(handleUserFlashNotification(user))
        dispatch(handleUserEmailVerificationNotification(user))
        dispatch(handleUserRequestDeletionNotification(user))
        if (isClientOrCustomer(user)) {
          if (user.whitelabelInfo.theme) {
            localStorage.setItem(
              'theme',
              JSON.stringify(user.whitelabelInfo.theme)
            )
            updateColorBased(user.whitelabelInfo.theme)
          }
          if (user.whitelabelInfo.favicon) {
            updateFavicon(user.whitelabelInfo.favicon)
          }
        } else if (user && user.ngineFavicon) {
          updateFavicon(user.ngineFavicon)
        }
      } else {
        dispatch(authFail(response.message))
        dispatch(failedLoaded())
      }
    }
    const onComplete = () => {}
    const onError = () => {
      toastCenter.messageServerError()
      dispatch(failedLoaded())
    }

    userDetailService.reload().subscribe((isReload) => {
      if (isReload) {
        isReload === 'client'
          ? clientDetailAPI.subscribe(onNext, onComplete, onError)
          : userDetailAPI.subscribe(onNext, onComplete, onError)
      }
    })

    currentView === 'client'
      ? clientDetailAPI.subscribe(onNext, onComplete, onError)
      : userDetailAPI.subscribe(onNext, onComplete, onError)
  } else {
    dispatch(logout())
  }
}
