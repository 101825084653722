import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import Modal from '../Modal/Modal'
import * as Router from '../../shared/router'
import * as actions from '../../store/actions/index'

const PaygWarningPopup = ({ show, popupText, onHide, paygPopupHide }) => {
  return (
    <Modal
      show={show}
      onHideModal={onHide}
      isForceShow={true}
      modalSize="modal-sm"
      useClose={true}
    >
      <div className="d-flex flex-column m-2">
        <div className="my-4">
          <p>{popupText}</p>
        </div>
        <div className="ml-auto">
          <NavLink
            to={Router.payg}
            onClick={() => paygPopupHide()}
            className="btn btn-darkprimary"
          >
            <span>Buy now</span>
          </NavLink>
        </div>
      </div>
    </Modal>
  )
}

PaygWarningPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  popupText: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) => {
  return {
    paygPopupHide: () => dispatch(actions.paygPopupHide()),
  }
}

export default connect(null, mapDispatchToProps)(PaygWarningPopup)
