import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  isPromptShow: false,
  status: '',
  cardId: '',
}

const createOrderShow = (state, data) => {
  return updateObject(state, {
    isPromptShow: true,
    status: data.status,
    cardId: data.cardId,
  })
}

const createOrderHide = (state) => {
  return updateObject(state, {
    isPromptShow: false,
    status: '',
    cardId: '',
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ORDER_SHOW:
      return createOrderShow(state, action)
    case actionTypes.CREATE_ORDER_HIDE:
      return createOrderHide(state)
    default:
      return state
  }
}

export default reducer
