import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './DashboardContainer.scss'
import * as actions from '../../store/actions/index'
import * as Router from '../../shared/router'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import Prompt from '../../components/Prompt/Prompt'
import { AbilityContext } from '../../context/abilityContext'
import { defineAbilitiesFor } from '../../shared/ability'
import FlashNotification from '../../components/FlashNotification/FlashNotification'

import branding from '../../shared/branding'
import TourGuide from '../../components/TourGuide/TourGuide'
import Main from './components/Main'
import Announcements from '../../components/Announcements/Announcements'
import SubscriptionLeaveModal from '../../components/SubscriptionLeaveModal/SubscriptionLeaveModal'
import PaymentMethodRequired from '../../components/PaymentMethodRequired/PaymentMethodRequired'
import { getCurrentView } from '../../shared/utility'
import useLiveChat from '../../hooks/useLiveChat'
import FreeTrialExpiredBanner from '../../components/FreeTrial/FreeTrialExpiredBanner'
import FreeTrialPopup from '../../components/FreeTrial/FreeTrialPopup'
import VideoTour from '../../components/TourGuide/VideoTour'
import PaygWarningPopup from '../../components/Payg/PaygWarningPopup'
// import CreateOrder from '../../components/PaygCreateOrderModal/CreateOrder'

let timeoutSidebarStyle = null

const DashboardContainer = ({
  isPromptShow,
  promptTitle,
  promptContent,
  promptActionOk,
  promptActionCancel,
  promptCancelCallback,
  buttonPromptType,
  promptChildComponent,
  promptType,
  isRedirectPath,
  targetRedirectPath,
  isAuthenticated,
  routeRemoveRedirect,
  location,
  userRole,
  user,
  paygPopupShow,
  paygPopupHide,
  paygPopup,
}) => {
  const notifRef = useRef('')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [
    isShowFreeTrialExpiredBanner,
    setIsShowFreeTrialExpiredBanner,
  ] = useState(false)
  const [paymentMethodRequiredShow, setPaymentMethodRequiredShow] = useState(
    false
  )
  const [freeTrialPopup, setFreeTrialPopup] = useState(null)
  // const [createOrderPopup, setCreateOrderPopup] = useState(false)

  useLiveChat(user)

  useEffect(() => {
    let localSetting = localStorage.getItem('settings')
    if (localSetting !== undefined && localSetting !== null) {
      localSetting = JSON.parse(localSetting)
      setIsFullScreen(localSetting.isFullScreen)
    } else {
      localSetting = { isFullScreen: false }
      localStorage.setItem('settings', JSON.stringify(localSetting))
    }
  }, [])

  useEffect(() => {
    if (location.pathname !== Router.paymentMethod) {
      if (user.isClientWhitelabel) {
        if (
          getCurrentView(user) === 'client' &&
          user.isProfileComplete &&
          user.isPaymentComplete === false &&
          !user.membership.freeTrial
        ) {
          setPaymentMethodRequiredShow(true)
        } else {
          setPaymentMethodRequiredShow(false)
        }
      } else {
        if (
          user.isProfileComplete &&
          user.isPaymentComplete === false &&
          !user.membership.freeTrial
        ) {
          setPaymentMethodRequiredShow(true)
        } else {
          setPaymentMethodRequiredShow(false)
        }
      }
    } else {
      setPaymentMethodRequiredShow(false)
      setFreeTrialPopup(null)
    }
  }, [location, user])

  const updateDashboardStyle = useCallback((height) => {
    const sidebarDOM = document.getElementById('sidebar')
    const dashboardDOM = document.getElementById('dashboard')
    sidebarDOM.style.marginTop = height + 'px'
    dashboardDOM.style.marginTop = height + 'px'
  }, [])

  useEffect(() => {
    timeoutSidebarStyle = setTimeout(() => {
      if (notifRef.current) {
        updateDashboardStyle(notifRef.current.clientHeight)
      }
    }, 750)

    return () => {
      if (timeoutSidebarStyle) {
        clearTimeout(timeoutSidebarStyle)
      }
    }
  }, [updateDashboardStyle])

  useEffect(() => {
    // remove redirection
    routeRemoveRedirect()
  }, [targetRedirectPath, routeRemoveRedirect])

  const toggle = () => {
    saveLocal(!isFullScreen)
    setIsFullScreen(!isFullScreen)
  }

  const saveLocal = (val) => {
    let localSetting = JSON.parse(localStorage.getItem('settings'))
    localSetting.isFullScreen = val
    localStorage.setItem('settings', JSON.stringify(localSetting))
  }

  let classes = 'dashboard'
  if (isFullScreen) {
    classes = 'dashboard active'
  }

  let lastLocationRedirect = null
  if (isRedirectPath && isAuthenticated) {
    lastLocationRedirect = <Redirect to={targetRedirectPath} />
  }

  const handleCloseAnnouncement = () => {
    setTimeout(() => {
      updateDashboardStyle(notifRef.current.clientHeight)
    }, 750)
  }

  const ability = defineAbilitiesFor(userRole)

  useEffect(() => {
    if (user && user.membership && user.membership.freeTrial) {
      if (user.membership.freeTrial.popup) {
        setFreeTrialPopup(user.membership.freeTrial.popup)
      } else {
        setFreeTrialPopup(null)
      }
      if (
        user.membership.freeTrial.isExpiredFreeTrial &&
        user.membership.freeTrial.banner
      ) {
        setIsShowFreeTrialExpiredBanner(true)
      } else {
        setIsShowFreeTrialExpiredBanner(false)
      }
    } else {
      setIsShowFreeTrialExpiredBanner(false)
    }

    if (user && user.membership && user.membership.latePaymentPopup) {
      setFreeTrialPopup(user.membership.latePaymentPopup)
    }

    if (user && user.payg && user.payg.allocatedHourWarning) paygPopupShow()
  }, [user, paygPopupShow])

  const handleCloseFreeTrialBanner = () => {
    setIsShowFreeTrialExpiredBanner(false)

    setTimeout(() => {
      updateDashboardStyle(notifRef.current.clientHeight)
    }, 750)
  }

  return (
    <AbilityContext.Provider value={ability}>
      <Helmet>
        <title>{branding.NAME} - Dashboard</title>
      </Helmet>
      <div className="announcement-wrapper" ref={notifRef}>
        <Announcements onClose={handleCloseAnnouncement} />
        {isShowFreeTrialExpiredBanner && user && user.membership.freeTrial && (
          <FreeTrialExpiredBanner
            onClose={handleCloseFreeTrialBanner}
            bannerText={user.membership.freeTrial.banner}
          />
        )}
        <FlashNotification />
      </div>
      <div
        className={`dashboard-wrapper ${
          location.pathname === Router.manageService ? 'overflow-auto' : ''
        }`}
      >
        {isAuthenticated ? <TourGuide /> : null}
        <div className="welcomeTourGuide"></div>
        {lastLocationRedirect}
        <Sidebar isFullScreen={isFullScreen} onToggle={toggle.bind(this)} />
        <div id="dashboard" className={classes}>
          <Header
            isFullScreen={isFullScreen}
            onToggle={toggle.bind(this)}
            user={user}
          />
          <Main />
        </div>

        <Prompt
          show={isPromptShow}
          title={promptTitle}
          content={promptContent}
          onActionOK={promptActionOk}
          onActionCancel={promptActionCancel}
          btnType={buttonPromptType}
          type={promptType}
          childComponent={promptChildComponent}
          cancelCallback={promptCancelCallback}
        />
      </div>

      {user && user.membership && (
        <SubscriptionLeaveModal
          reactivatedDate={
            user.membership.onSubscriptionLeaveReactivationDateFormat
          }
          show={user.membership.onSubscriptionLeave}
        />
      )}

      <PaymentMethodRequired
        show={paymentMethodRequiredShow}
        onHide={() => false}
      />

      <FreeTrialPopup
        show={freeTrialPopup !== null ? true : false}
        onHide={() => setFreeTrialPopup(null)}
        popupText={freeTrialPopup !== null ? freeTrialPopup : ''}
      />

      <PaygWarningPopup
        show={paygPopup.isPaygPopupShow}
        onHide={() => paygPopupHide()}
        popupText={
          user && user.payg && user.payg.wordingWarning !== null
            ? user.payg.wordingWarning
            : ''
        }
      />

      {/* <CreateOrder
        show={createOrderPopup}
        onHideModal={() => setCreateOrderPopup(false)}
      /> */}

      {user && user.tourVideo && <VideoTour videoUrl={user.tourVideo} />}
    </AbilityContext.Provider>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    userRole: state.auth.role,
    isPromptShow: state.prompt.isPromptShow,
    promptTitle: state.prompt.promptTitle,
    promptContent: state.prompt.promptContent,
    buttonPromptType: state.prompt.buttonPromptType,
    promptChildComponent: state.prompt.childComponent,
    promptCancelCallback: state.prompt.cancelCallback,
    promptType: state.prompt.promptType,
    isRedirectPath: state.route.isRedirectPath,
    targetRedirectPath: state.route.targetRedirectPath,
    user: state.user,
    paygPopup: state.paygPopup,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    promptActionOk: () => dispatch(actions.promptActionOk()),
    promptActionCancel: () => dispatch(actions.promptActionCancel()),
    routeRemoveRedirect: () => dispatch(actions.routeRemoveRedirect()),
    paygPopupHide: () => dispatch(actions.paygPopupHide()),
    paygPopupShow: () => dispatch(actions.paygPopupShow()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
)
