import React from 'react';
import PropTypes from 'prop-types';

import './ProgressBar.scss';

const ProgressBar = ({ valuenow, valuemin, valuemax, isGradient = false }) => {
  const valueCurrent = valuemin > 0 ? valuemin : valuenow;
  const percentOfUsed = ((valueCurrent / valuemax) * 100);
  const progressUsed = percentOfUsed > 100 ? 100 : percentOfUsed;

  return (
    <div className="progress-container">
      <div className="progress">
        {isGradient
          ? (
            <div className={"progress-bar bg-gradient"}
              style={{ width: `100%` }}></div>
          )
          : (
            <div className={parseInt(progressUsed) >= 90 ? "progress-bar bg-danger" : "progress-bar bg-primary"}
              style={{ width: `${progressUsed}%` }}></div>
          )}
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  valuenow: PropTypes.number.isRequired,
  valuemin: PropTypes.number.isRequired,
  valuemax: PropTypes.number.isRequired
}

export default ProgressBar;