import React, { useEffect } from 'react';
import clientLogin from './shared/clientLogin';

export const Theme = ({ isAuthenticated }) => {
  useEffect(() => { 
    const clientId = localStorage.getItem("clientId");
    if (clientId === clientLogin.ADMIN) {
      document.body.classList.add('theme-ngine');
    } else {
      document.body.classList.remove('theme-ngine');
    }
  }, [isAuthenticated]);

  return (
    <div></div>
  )
};
