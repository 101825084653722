import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  isPaygPopupShow: false,
}

const paygPopupShow = (state) => {
  return updateObject(state, {
    isPaygPopupShow: true,
  })
}

const paygPopupHide = (state) => {
  return updateObject(state, {
    isPaygPopupShow: false,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAYG_POPUP_SHOW:
      return paygPopupShow(state)

    case actionTypes.PAYG_POPUP_HIDE:
      return paygPopupHide(state)

    default:
      return state
  }
}

export default reducer
