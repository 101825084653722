import React, { useState, useEffect } from 'react'
import Modal from '../Modal/Modal'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import { startTourGuideService } from '../../services/tourGuideService/restartTourGuideService'
import { startTourVideoService } from '../../services/tourGuideService/tourVideo'
import branding from '../../shared/branding'

function AfterEditProfileTourGuide({ user }) {
  const [autoRun, setAutoRun] = useState(false)

  const startTourGuide = () => {
    window.location.href = '/'
  }

  const startTourVideo = () => {
    tourGuideAction('all', 'false', () => {
      setAutoRun(false)
      userDetailService.emitReload(true)
      startTourVideoService.emitReload(true)
    })
  }

  const skipTourGuide = () => {
    tourGuideAction('all', 'false', () => {
      userDetailService.emitReload(true)
      window.location.href = '/'
    })
  }

  useEffect(() => {
    const subscription = startTourGuideService.reload().subscribe((value) => {
      if (value) {
        setAutoRun(value)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <Modal
      show={autoRun}
      onHideModal={setAutoRun}
      modalSize="modal-sm"
      isForceShow
      useClose={false}
    >
      <div
        className="afterEeditProfileTour"
        style={{ minHeight: '100px', textAlign: 'center' }}
      >
        <div>
          <p>
            Thank you for updating your profile.
            <br />
            {user && user.tourVideo
              ? `Click [Start Tour] to begin your ${branding.NAME} Tour Guide or Click [Start Video] to watch ${branding.NAME} Video or you
            can [Skip] for now.`
              : `Click [Start Tour] to begin your ${branding.NAME} Tour Guide or you can [Skip] for now.`}
            <br />
            {user && user.tourVideo
              ? `You can always restart your Tour Guide or Video in the General Settings`
              : `You can always restart your Tour Guide in the General Settings`}
          </p>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-secondary mr-3"
            onClick={skipTourGuide}
          >
            Skip
          </button>
          <button
            type="button"
            className="btn btn-darkprimary mr-3"
            onClick={startTourGuide}
          >
            Start Tour
          </button>
          {user && user.tourVideo && (
            <button
              type="button"
              className="btn btn-darkprimary"
              onClick={startTourVideo}
            >
              Start Video
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default AfterEditProfileTourGuide
