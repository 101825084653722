import React from 'react'

const SvgNgineArt = (props) => (
  <svg
    data-name="Layer 1"
    viewBox="0 0 225.3 227.65"
    className="ngine-art-logo"
    {...props}
  >
    <path
      id="blue"
      fill="#6fcfeb"
      d="M146.52 24.31a94.56 94.56 0 10-96.25 162.8c48.84 28.88 141.5 12.14 158.35-16.36s-13.26-117.57-62.1-146.44zm-33.84 127a37.53 37.53 0 1137.54-37.53 37.52 37.52 0 01-37.54 37.53z"
    />
    <path
      id="red"
      fill="#eb0045"
      d="M161 13.78C131.1-.52 45 37.23 20.48 88.4a94.56 94.56 0 10170.57 81.7c24.51-51.18-.24-142.03-30.05-156.32zm-48.3 137.57a37.53 37.53 0 1137.53-37.53 37.53 37.53 0 01-37.58 37.53z"
    />
  </svg>
)

export default SvgNgineArt
