export const AUTH_START = 'AUTH_START'
export const AUTH_STOP = 'AUTH_STOP'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'

export const ORDER_DETAIL_SHOW = 'ORDER_DETAIL_SHOW'
export const ORDER_DETAIL_HIDE = 'ORDER_DETAIL_HIDE'

export const PROMPT_SHOW = 'PROMPT_SHOW'
export const PROMPT_HIDE = 'PROMPT_HIDE'
export const PROMPT_ACTION_OK = 'PROMPT_ACTION_OK'
export const PROMPT_ACTION_CANCEL = 'PROMPT_ACTION_CANCEL'

export const ROUTE_PATH_REDIRECT = 'ROUTE_PATH_REDIRECT'
export const ROUTE_HAS_REDIRECTED = 'ROUTE_HAS_REDIRECTED'

export const API_START = 'API_START'
export const API_STOP = 'API_STOP'

export const LOAD_DESIGN_ORDER_SUCCEED = 'LOAD_DESIGN_ORDER_SUCCEED'
export const RESET_DESIGN_ORDER_COLUMNS = 'RESET_DESIGN_ORDER_COLUMNS'
export const UPDATE_COLUMNS = 'UPDATE_COLUMNS'

export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS'
export const USER_LOADED_FAILED = 'USER_LOADED_FAILED'

export const TOOLTIP_SHOW = 'TOOLTIP_SHOW'
export const TOOLTIP_HIDE = 'TOOLTIP_HIDE'

export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT'
export const NOTIFICATION_MESSAGES = 'NOTIFICATION_MESSAGES'
export const NOTIFICATION_PUSH_MESSAGE = 'NOTIFICATION_PUSH_MESSAGE'

export const PROMPT_REASON_SHOW = 'PROMPT_REASON_SHOW'
export const PROMPT_REASON_HIDE = 'PROMPT_REASON_HIDE'

export const QUICK_SEARCH = 'QUICK_SEARCH'
export const QUICK_SEARCH_CLEAR = 'QUICK_SEARCH_CLEAR'
export const QUICK_SEARCH_RESULT = 'QUICK_SEARCH_RESULT'

export const PRESET_FILTER = 'PRESET_FILTER'
export const PRESET_FILTER_CLEAR = 'PRESET_FILTER_CLEAR'

export const ARCHIVED_PRESET_FILTER = 'ARCHIVED_PRESET_FILTER'
export const ARCHIVED_PRESET_FILTER_CLEAR = 'ARCHIVED_PRESET_FILTER_CLEAR'

export const BACKDROP_TOGGLE = 'BACKDROP_TOGGLE'

export const FLASH_NOTIFICATION_SHOW = 'FLASH_NOTIFICATION_SHOW'
export const FLASH_NOTIFICATION_HIDE = 'FLASH_NOTIFICATION_HIDE'

export const ARCHIVED_QUICK_SEARCH = 'ARCHIVED_QUICK_SEARCH'
export const ARCHIVED_QUICK_SEARCH_CLEAR = 'ARCHIVED_QUICK_SEARCH_CLEAR'

export const CREATE_ORDER_SHOW = 'CREATE_ORDER_SHOW'
export const CREATE_ORDER_HIDE = 'CREATE_ORDER_HIDE'

export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const SUB_CART_QTY = 'SUB_CART_QTY'
export const RESET_CART = 'RESET_CART'

export const PAYG_POPUP_SHOW = 'PAYG_POPUP_SHOW'
export const PAYG_POPUP_HIDE = 'PAYG_POPUP_HIDE'
