import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import * as Router from '../../../shared/router'
import * as actions from '../../../store/actions/index'
import { socketRemoveAllListeners } from '../../../socket/socket'

const Logout = ({ onLogout }) => {
  useEffect(() => {
    socketRemoveAllListeners()
    onLogout()
  }, [onLogout])

  return <Redirect to={Router.signin} />
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  }
}

export default connect(null, mapDispatchToProps)(Logout)
