import * as actionTypes from './actionTypes'

export const addToCart = (id, itemType) => {
  return { type: actionTypes.ADD_TO_CART, id, itemType }
}

export const removeFromCart = (id) => {
  return { type: actionTypes.REMOVE_FROM_CART, id }
}

export const subFromCart = (item) => {
  return { type: actionTypes.SUB_CART_QTY, item }
}

export const resetCart = () => {
  return { type: actionTypes.RESET_CART }
}
