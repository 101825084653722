import 'es6-shim'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'geometry-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import authReducer from './store/reducers/auth'
import promptReducer from './store/reducers/prompt'
import routeReducer from './store/reducers/route'
import apiReducer from './store/reducers/api'
import userDetailReducer from './store/reducers/userDetail'
import notificationReducer from './store/reducers/notification'
import promptReasonReducer from './store/reducers/promptReason'
import flashNotificationReducer from './store/reducers/flashNotification'
import quickSearchReducer from './store/reducers/quickSearch'
import archivedQuickSearchReducer from './store/reducers/archivedQuickSearch'
import presetFilterReducer from './store/reducers/presetFilter'
import archivedPresetFilterReducer from './store/reducers/archivedPresetFilter'
import backdropReducer from './store/reducers/backdrop'
import createOrderReducer from './store/reducers/createOrder'
import paygCartReducer from './store/reducers/paygCart'
import paygPopupReducer from './store/reducers/paygPopup'
import * as serviceWorker from './serviceWorker'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-away.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import App from './App'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { sentryDNS } from './shared/constant'

const rootReducer = combineReducers({
  auth: authReducer,
  prompt: promptReducer,
  route: routeReducer,
  user: userDetailReducer,
  api: apiReducer,
  notification: notificationReducer,
  promptReason: promptReasonReducer,
  quickSearch: quickSearchReducer,
  presetFilter: presetFilterReducer,
  archivedPresetFilter: archivedPresetFilterReducer,
  backdrop: backdropReducer,
  archivedQuickSearch: archivedQuickSearchReducer,
  flashNotification: flashNotificationReducer,
  createOrder: createOrderReducer,
  paygCart: paygCartReducer,
  paygPopup: paygPopupReducer,
})

const logger = (store) => {
  return (next) => {
    return (action) => {
      const result = next(action)
      return result
    }
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(logger, thunk))
)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: sentryDNS })
}

const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
)
ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
