import socketIOClient from 'socket.io-client'

import { socketHost } from '../shared/constant'
import clientLogin from '../shared/clientLogin'
import { logging } from '../shared/utility'

const socket = socketIOClient(socketHost)

export const socketEmit = (event, params) => {
  socket.emit(event, params)
}

export const socketListen = (event, callback) => {
  logging('[socketListen]', event)

  socket.on(event, callback)
}

export const getParamLoginEmit = (clientId, token) => {
  const userType = clientId === clientLogin.CUSTOMER ? 'customer' : 'user'

  return { userType: userType, token: token }
}

export const socketRemoveEventListener = (event, callback) => {
  logging('[socketRemoveEventListener]', event)

  socket.removeEventListener(event, callback)
}

export const socketRemoveAllListeners = () => {
  logging('[socketRemoveAllListeners]')

  socket.removeAllListeners()
}
