import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  items: JSON.parse(localStorage.getItem('cart')),
}

const addToCart = (state, action) => {
  const localCart = JSON.parse(localStorage.getItem('cart'))
  let isExist = false
  if (localCart) {
    for (const item of localCart) {
      if (item.id === action.id) {
        item.qty += 1
        isExist = true
      }
    }
    if (!isExist) {
      localCart.push({
        id: action.id,
        qty: 1,
        type: action.itemType,
      })
    }
    localStorage.removeItem('cart')
    localStorage.setItem('cart', JSON.stringify(localCart))
    return updateObject(state, {
      items: localCart,
    })
  } else {
    const newItem = [{ id: action.id, qty: 1, type: action.itemType }]

    localStorage.setItem('cart', JSON.stringify(newItem))

    return updateObject(state, {
      items: newItem,
    })
  }
}

const removeFromCart = (state, action) => {
  const cart = JSON.parse(localStorage.getItem('cart'))
  const newCart = cart.filter((cartItem) => {
    return cartItem.id !== action.id
  })
  localStorage.setItem('cart', JSON.stringify(newCart))
  return updateObject(state, {
    items: newCart,
  })
}

const subFromCart = (state, action) => {
  let removedCart = []
  if (action.item.qty > 1) {
    removedCart = JSON.parse(localStorage.getItem('cart')).map((item) => {
      if (item.id === action.item.id && item.qty > 1) {
        return {
          id: item.id,
          type: item.type,
          qty: item.qty - 1,
        }
      }

      return item
    })
  } else {
    removedCart = JSON.parse(localStorage.getItem('cart')).filter(
      (cartItem) => {
        return cartItem.id !== action.item.id
      }
    )
  }

  localStorage.setItem('cart', JSON.stringify(removedCart))
  return updateObject(state, {
    items: removedCart,
  })
}

const resetCart = (state) => {
  localStorage.setItem('cart', JSON.stringify([]))
  return updateObject(state, {
    items: [],
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      return addToCart(state, action)
    case actionTypes.REMOVE_FROM_CART:
      return removeFromCart(state, action)
    case actionTypes.SUB_CART_QTY:
      return subFromCart(state, action)
    case actionTypes.RESET_CART:
      return resetCart(state)
    default:
      return state
  }
}

export default reducer
