import { adobeEmbedApiClientId } from '../shared/constant'

class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve()
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve()
        })
      }
    })
    this.adobeDCView = undefined
  }

  ready() {
    return this.readyPromise
  }

  previewFile(divId, viewerConfig, url, fileName, fileId) {
    const config = {
      clientId: adobeEmbedApiClientId,
    }

    if (divId) {
      config.divId = divId
    }

    this.adobeDCView = new window.AdobeDC.View(config)
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: url,
          },
        },
        metaData: {
          fileName: fileName,
          id: fileId,
        },
      },
      viewerConfig
    )

    // console.log(this.adobeDCView)

    // this.adobeDCView.registerCallback(
    //   window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
    //   (event) => {
    //     console.log(event)
    //   }
    //   // function (error) {
    //   //   console.error('Adobe Embed API Error:', error)
    //   //   // Check if error occurred on a specific page
    //   //   if (error.data && error.data.pageNumber === 1) {
    //   //     console.error('Rendering failed on page', 2)
    //   //     // Handle the error on this specific page
    //   //   }
    //   // }
    // )

    return previewFilePromise
  }
}

export default ViewSDKClient
