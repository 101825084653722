import React from 'react'
import PropTypes from 'prop-types'

import './NgineApiLoader.scss'
import SvgNgineArt from './SvgNgineArt'

const NgineApiLoader = ({ isLoading = false, className = '' }) => {
  return (
    <div
      className={`api-loader-logo-wrapp ${className} ${
        isLoading ? 'show' : 'd-none'
      }`}
    >
      <div className="d-flex flex-column position-relative api-loader-shadow">
        <div className="api-loader-logo">
          <SvgNgineArt />
        </div>
      </div>
    </div>
  )
}

NgineApiLoader.propType = {
  isLoading: PropTypes.bool.isRequired,
}

export default NgineApiLoader
